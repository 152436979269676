export class ApiConstants {
    public static apiEndPoints = {
        auth: {
            login: 'StaffLoginLambdaFunction'
        },
        user: {
            create: 'webadminstaffCreate',
            list: 'webadminStaffList',
            detail: 'webadminStaffDetail',
            update: 'webadminStaffUpdateDetail',
            delete: 'webadminStaffDelete',
            userGroups: 'webadminAssignGroupList',


        },
        group: {
            create: 'webadminStaffGroupCreate',
            list: 'webadminStaffGroupList',
            detail: 'webadminStaffGroupDetail',
            update: 'webadminStaffGroupUpdateDetail',
            delete: 'webadminStaffGroupDelete',
            assignGroup: 'webadminAssignGroup'

        },
        droplist: {
            create: 'obj_lbl_Droplist_insert',
            list: 'single_droplist_list_all',
            // list: 'single_droplist_list_active',
            update: 'obj_lbl_Droplist_update',
            active: 'single_label_active',
            deactivate: 'single_label_deactive'
        },
        multidroplist: {
            list: 'multi_droplist_list_all',
            active: 'multi_label_active',
            deactive: 'multi_label_deactive'
        }
        // https://xz60epwn52.execute-api.ap-southeast-1.amazonaws.com/single_droplist_list_active
    };
}