<footer class="footer">
  <div class="container-fluid text-center">
    <div class="row">
      <!-- <div class="col-sm-6">
        {{year}} © Minible.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          Crafted with <i class="mdi mdi-heart text-danger"></i> by <a href="https://themesbrand.com/" target="_blank"
            class="text-reset">Themesbrand</a>
        </div>
      </div> -->
      <!-- <span>Copyright &copy; YOH-Admin {{year}}</span> -->
      <app-context-menu [key]="'Dashboard-Footer-Label'" [text]="'DashboardFooter'" [default]="'DashboardFooter'">
      </app-context-menu>
    </div>
  </div>
</footer>
