import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import { environment } from '../../../../environments/environment';

import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user/user.model';
import { HttpClient } from '@angular/common/http';
import { language } from 'src/app/models/apiService.model';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  configData;
  cookieValue;
  flagvalue: any;
  countryName: any;
  valueset;
  LoginUserName!: string;
  languageData: language = {};
  defaultSelectedlanguage!: string;
  dropListData = {
    data: []
  };
  phoneCodeData = {
    data: []
  };

  constructor(@Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private httpClient: HttpClient, private loaderService: LoaderService) {
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    let data = JSON.parse(localStorage.getItem('currentToken')!) as User;
    this.LoginUserName = data.user_firstname + ' ' + data.user_lastname;
    this.defaultSelectedlanguage = 'English';
    this.getPermission();
    this.getLanguage();
  }

  /**
   * Language set
   * @param text 
   * @param lang 
   * @param flag 
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    //user logout
    // if (environment.defaultauth === 'firebase') {
    //   this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    // this.router.navigate(['/account/login']);
    this.authService.logout();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    }
  }

  switchLanguage(event) {
    var languageName = event.target.value;
    localStorage.removeItem('activeLanguage');
    localStorage.setItem('activeLanguage', languageName);
    this.defaultSelectedlanguage = languageName == 'EN' ? 'English' : 'Thai';
    this.tempLanguage(languageName);
  }

  getPermission() {
    let id = JSON.parse(localStorage.getItem('currentToken')!);
    this.httpClient
      .get(
        'https://asset.yoyoyoh.com/permission_jsontext/' + id.permission_id + '.json',
        // 'https://asset.yoyoyoh.com/permission_jsontext/0af5b89d3c25beca0d11319ecde8ce15.json',
        { responseType: 'text' }
      )
      .subscribe((x) => {
        localStorage.setItem('permission', x);
      });
  }
  getLanguage() {
    this.httpClient.get('https://version.yoyoyoh.com/version.yaml', { responseType: 'text' })
      .subscribe((x) => {
        // let data = JSON.parse(x).versions[0].filename;
        // if (localStorage.getItem('language')) {
        //   if (!localStorage.getItem('languageVersion')) {
        //     localStorage.setItem('languageVersion', data);
        //   }
        // }
        localStorage.setItem('languagesUrl', x);
        this.tempLanguage('EN');
        this.getDropList();
      });
  }
  tempLanguage(languageCode: string) {
    let data: any = JSON.parse(localStorage.getItem('languagesUrl')!);
    let url = (data['web_versions'][0]['url'])
    let filename = (data['web_versions'][0]['filename']);
    filename = filename.find((o: { filename: string, filetype: string, language: string }) => o.language == languageCode && o.filetype == 'label').filename;

    this.loaderService.start();
    this.httpClient.get(url + '/' + filename, { responseType: 'text' })
      .subscribe((x) => {
        localStorage.removeItem('language');
        localStorage.setItem('language', this.transformToLanguage(x));
        this.languageService.setLanguageYoh(JSON.parse(this.transformToLanguage(x)));
      });
    this.loaderService.stop();
  }
  transformToLanguage(data: any): any {
    let languageData = JSON.parse(data)['Records'];
    for (var d of languageData) {
      let key = Object.keys(d)[0];
      let value = Object.values<string>(d)[0];
      this.languageData[key] = value;
    }
    return JSON.stringify(this.languageData);
  }


  getDropList() {
    let data: any = JSON.parse(localStorage.getItem('languagesUrl')!);
    let url = (data['web_versions'][0]['url'])
    let filename = (data['web_versions'][0]['filename']);
    filename = filename.find((o: { filename: string, filetype: string, language: string }) => o.language == 'EN' && o.filetype == 'droplist').filename;

    // this.loaderService.start();
    this.httpClient.get(url + '/' + filename, { responseType: 'text' })
      .subscribe((x) => {

        localStorage.removeItem('dropList');
        localStorage.setItem('dropList', this.transformToDropList(x));
        this.languageService.setLanguageYoh(JSON.parse(this.transformToDropList(x)));
        // this.loaderService.stop();
      });
  }

  transformToDropList(data: any): any {
    this.dropListData.data = JSON.parse(data)['language_group']['language'];
    // for (var d of dropListData) {
    //   let key = Object.keys(d)[0];
    //   let value = Object.values<string>(d)[0];
    //   this.dropListData[key] = value;
    // }
    return JSON.stringify(this.dropListData);
  }

}
