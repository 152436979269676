import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { EnvoirmentService } from "./envoirment.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    authReq: any;
    constructor(private router: Router, private envService: EnvoirmentService) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {

        if (err.status === 401 || err.status === 403) {
            // if (err.status === 401) {
            this.clearStorage();
            // this.router.navigateByUrl('account/login');
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('json') || req.url.includes('version.yaml')) {
            this.authReq = req.clone({
                // headers: req.headers.set('generaltoken', this.envService.GeneralToken)
            })
        }
        else if (req.url.includes('StaffLoginLambdaFunction')) {
            this.authReq = req.clone({
                headers: req.headers.set('generaltoken', this.envService.GeneralToken)
            })
        }
        else if (!req.url.includes('StaffLoginLambdaFunction')) {
            this.authReq = req.clone({
                headers: req.headers.set('generaltoken', this.envService.GeneralToken).set('secret', 'Bearer ' + JSON.parse(localStorage.getItem('token')!))
            })
        }

        // setTimeout(() => {
        //     this.clearStorage();
        // }, 600000);

        return next.handle(this.authReq).pipe(catchError(x => this.handleAuthError(x)));

    }
    clearStorage() {
        localStorage.removeItem('currentToken');
        localStorage.removeItem('token');
        localStorage.removeItem('permission');
        // localStorage.removeItem('language');
        localStorage.removeItem('languagesUrl');
        localStorage.removeItem('phoneCode');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('dropList');
        this.router.navigate(['account/login']);
    }
}