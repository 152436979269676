
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Permission, PermissionDetails } from 'src/app/models/apiService.model';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    $permission = new Subject<Permission>();

    constructor() {
    }

    public setPermission(permissionData: Permission) {
        this.$permission.next(permissionData);
    }
    public getUserPermision(key: any): PermissionDetails {
        const userData = JSON.parse(localStorage.getItem('currentToken')!);
        const data = JSON.parse(localStorage.getItem('permission')!);
        if (!data) {
            const permissionObj: PermissionDetails = {
                isView: false,
                isEdit: false
            }
            return permissionObj
        }
        const permission = data[key];
        if (!permission) {
            const permissionObj: PermissionDetails = {
                isView: userData.su_admin == 1 ? true : false,
                isEdit: userData.su_admin == 1 ? true : false
            }
            return permissionObj
        }

        const permissionObj: PermissionDetails = {
            isView: permission[0] == '1' ? true : false,
            isEdit: permission[1] == '1' ? true : false
        }
        return permissionObj
    }
}